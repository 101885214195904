import React from "react";
import { LocalStorages } from "../../utils/localStorages";
import TutorialDialog from "../Feature/TutorialDialog";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";

import "./index.scss";

interface ILayoutContainer {
  children: React.ReactNode;
}

const LayoutContainer: React.FC<ILayoutContainer> = ({ children }) => {
  const showTutorial = LocalStorages.getTutorialStatus();

  return (
    <div className="layout-container">
      <Header hideSearchBar={false} />
      <div className="layout-content-container">
        <Sidebar />
        <div className={`content-container collapsed`}>
          {showTutorial && <TutorialDialog />}
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LayoutContainer;
